/* eslint-disable react/jsx-no-bind */
import { IMeeting } from 'types';
import { useCallback, useRef, useState } from 'react';
import {
  useResourceList,
  useUserShow,
  useWorkspaceShow,
  useLocale,
  useComponentVisibility,
  useResourceShow,
  useParamsKey
} from '@koopajs/react';
import {
  Button,
  Box,
  Breadcrumbs,
  Stack,
  Typography,
  Link,
  Paper,
  Divider,
  Tooltip,
  IconButton,
  ClickAwayListener,
  List,
  ListItem
} from '@mui/material';
import { IUserPublicProfile } from '@koopajs/app';
import { calculateMeetingDuration } from 'utils/calculateMeetingDuration';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { DeleteMeeting } from '../components/Modals/DeleteMeeting';
import { checkIsEndTopic, checkIsUnmovableTopicType } from 'utils/topicTypeArrays';
import { AddTopicButton } from '../components/Meetings/AddTopicButton';
import { MeetingEditHeaderCard } from '../components/Meetings/Edit/MeetingEditHeaderCard';
import { MeetingStateChip } from '../components/Meetings/MeetingStateChip';
import { MeetingTopicCardEditableMovable } from '../components/Meetings/MeetingTopicCardEditable/MeetingTopicCardEditableMovable';
import { MeetingPrintVersion } from '../components/Meetings/MeetingPrintVersion';
import { RenderPrintButton } from 'components/RenderPrintButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { VisibleByAccordion } from 'components/VisibleByAccordion';
import { PageContainer } from 'components/temp/PageContainer';
import { calculateTopicsStartTime } from 'utils/calculateTopicsStartTime';
import { ListAlt as ListAltIcon } from '@mui/icons-material';
import { StartMeetingBanner } from 'components/Meetings/StartMeetingBanner';
import { EventsTimeline } from 'components/EventsTimeline';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { MeetingLoadingOrError } from 'components/Meetings/MeetingLoadingOrError';
import { Loading } from '@koopajs/mui';
import { Trans } from 'react-i18next';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

export const i18nTransComponents: { [k: string]: React.ReactElement } = {
  span: <span style={{ color: 'grey' }} />
};

export const MeetingEdit: React.FC = (props) => {
  const { t } = useLocale();

  const meetingId = useParamsKey('meetingId');
  const {
    resource: meeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: meetingId
  });

  const { user: currentUser } = useUserShow();

  const history = useHistory();

  const { isUserAuthorizedEditor } = useMeetingStatus({ meeting, user: currentUser });

  /*
  REDIRECT
  */
  const doesUserHaveAccessToPage = isUserAuthorizedEditor && !meeting?.minutesStartedAt;
  if (meeting && !doesUserHaveAccessToPage) {
    history.push(`/meetings/${meetingId}`);
  }

  const { resources: users } = useResourceList<IUserPublicProfile>({
    path: '/users',
    searchParams: { size: 50 }
  });
  const workspace = useWorkspaceShow();

  const sendMeetingDialog = useComponentVisibility('sendMeeting');

  const keyPrefix = 'MeetingEdit';

  const [isMeetingEditable, setMeetingEditable] = useState(false);
  const [topicIdEditable, setTopicIdEditable] = useState('');
  const [isDeleteMeetingOpen, setIsDeleteMeetingOpen] = useState(false);
  const [hasClickedSendMeeting, setHasClickedSendMeeting] = useState(false);
  const handleSetTopicEditable = useCallback((topicId: string) => {
    return () => setTopicIdEditable(topicId);
  }, []);
  const handleUnsetTopicEditable = useCallback(() => {
    setTopicIdEditable('');
  }, []);
  const handleOpenDeleteModal = useCallback(() => {
    setIsDeleteMeetingOpen(true);
  }, []);
  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteMeetingOpen(false);
  }, []);

  const [isGuideTooltipOpen, setIsGuideTooltipOpen] = useState(false);
  const handleGuideTooltipClose = () => {
    setIsGuideTooltipOpen(false);
  };
  const handleGuideTooltipOpen = () => {
    setIsGuideTooltipOpen(true);
  };

  const requiredPropertiesErrorMessages: string[] = [
    'missing_location_type',
    'missing_location_address',
    'missing_participants',
    'missing_date',
    'missing_time_start',
    'missing_time_end',
    'missing_date_time'
  ];

  const isMissingRequiredProperties = meeting?.errorMessages?.some((errorMessage) =>
    requiredPropertiesErrorMessages.includes(errorMessage)
  );
  const handleShowSendMeeting = useCallback(
    (value: boolean) => {
      return () => {
        setHasClickedSendMeeting(true);

        if (!isMissingRequiredProperties) {
          sendMeetingDialog.setVisibleWithContext({
            meeting: meeting,
            defaultValues: { title: meeting?.title }
          });
        }
      };
    },
    [isMissingRequiredProperties, JSON.stringify(meeting)]
  );

  const topics = meeting && calculateTopicsStartTime(meeting);

  const filteredTopicsStart = topics?.filter((topic) => !checkIsEndTopic(topic.type));
  const filteredTopicsEnd = topics?.filter((topic) => checkIsEndTopic(topic.type));

  const notEndTopicCount =
    meeting?.topics?.reduce((acc, topic) => {
      return !checkIsEndTopic(topic.type) ? acc + 1 : acc;
    }, 0) || 0;

  const renderSendMeetingButton = (props?: { sxButton?: SxProps<Theme> }): React.ReactNode => {
    const { sxButton } = props || {};

    return (
      <>
        <Tooltip title={isMissingRequiredProperties ? t(keyPrefix + '.tooltipMissingRequiredFields') : ''}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              onClick={handleShowSendMeeting(true)}
              variant={meeting?.invitationSentAt ? 'outlined' : 'contained'}
              data-cy="meeting-create-send-button"
              sx={{ ...sxButton }}
            >
              {meeting?.invitationSentAt
                ? t(keyPrefix + '.buttonLabelResendMeeting')
                : t(keyPrefix + '.buttonLabelSendMeeting')}
            </Button>
          </Box>
        </Tooltip>
      </>
    );
  };

  const printComponentRef = useRef(null);

  const meetingParticipants = users.filter((user) =>
    meeting?.participants?.find((participant) => participant.userId === user.id)
  );

  const timelineEvents = generateMeetingTimeline(meeting);

  if (!meeting) {
    return <MeetingLoadingOrError isProcessing={isProcessing} errorMessage={errorMessage} />;
  } else if (!doesUserHaveAccessToPage) {
    // loading component while we wait for the redirect to the view page
    return (
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
      </Stack>
    );
  }

  return (
    <>
      <Box sx={{ display: 'none' }}>
        <MeetingPrintVersion meeting={{ ...meeting, topics }} ref={printComponentRef} />
      </Box>
      <PageContainer
        testId="meeting-edit-page"
        sxChildren={{ padding: { xs: 3, md: '24px 56px' } }}
        className="rr-block"
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Link underline="hover" color="inherit" component={RouterLink} to={'/'} sx={{ cursor: 'pointer' }}>
            {t('common:navigation.dashboard')}
          </Link>
          <Typography color="text.primary">{meeting.title || ''}</Typography>
        </Breadcrumbs>

        <Box>
          <Stack
            direction="row"
            alignItems="center"
            flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
            sx={{ mt: 4, mb: 2 }}
          >
            <Button
              component={RouterLink}
              to="/"
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' }, mr: '14px' }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
            <Stack flexDirection="row" alignItems="center">
              <Typography
                variant="h1"
                sx={{ display: 'inline-block', fontWeight: 400, fontSize: '24px' }}
                data-cy="meeting-prepare_title"
              >
                {meeting.title || ''}
              </Typography>
              <ClickAwayListener onClickAway={handleGuideTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          p: 0,
                          backgroundColor: 'transparent',
                          maxWidth: '600px',
                          fontWeight: 400
                        }
                      }
                    }}
                    onClose={handleGuideTooltipClose}
                    open={isGuideTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <Paper
                        sx={{
                          border: '1px solid #F8981C'
                        }}
                      >
                        <Box sx={{ backgroundColor: 'rgba(248, 152, 28, 0.15)', p: 3 }}>
                          <Typography variant="h6">{t(keyPrefix + '.guideTooltip.title')}</Typography>
                          <List
                            sx={{
                              listStyle: 'decimal',
                              listStylePosition: 'inside',
                              fontSize: 'initial',
                              '& > li::marker': { fontWeight: 'bold' }
                            }}
                          >
                            {['bullet1', 'bullet2', 'bullet3', 'bullet4', 'bullet5'].map((bullet) => (
                              <ListItem key={bullet} disablePadding sx={{ display: 'list-item' }}>
                                <Trans i18nKey={keyPrefix + `.guideTooltip.${bullet}`} t={t} />
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Paper>
                    }
                  >
                    <IconButton
                      aria-label={t(keyPrefix + '.guideTooltip.ariaLabel')}
                      sx={{ color: '#F8981C' }}
                      onClick={handleGuideTooltipOpen}
                    >
                      <LightbulbIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Stack>
            {/* CHIP */}
            {meeting.minutesStartedAt && (
              <MeetingStateChip meeting={meeting} sx={{ ml: { xs: 'auto', sm: 3 } }} />
            )}

            {/* SEND MEETING BUTTON */}
            <Stack
              flexDirection="row"
              gap="20px"
              sx={{ ml: { sm: 'auto' }, mt: { xs: 3, sm: 0 }, flexBasis: { xs: '100%', sm: 'auto' } }}
            >
              <Button
                component={RouterLink}
                to={{
                  pathname: `/meetings/${meeting.id}`,
                  state: { activePath: '/' }
                }}
              >
                {t('common:labelView')}
              </Button>
              {renderSendMeetingButton({ sxButton: { width: { xs: '100%', sm: 'auto' } } })}
            </Stack>
          </Stack>

          <Box
            sx={{
              background: 'linear-gradient(to right, transparent 32px, #bdbdbd 8px)',
              backgroundSize: '40px',
              backgroundRepeat: 'no-repeat'
            }}
          >
            {/* MEETING DETAILS */}
            <Box sx={{ my: 3 }}>
              <MeetingEditHeaderCard
                meeting={meeting}
                users={users}
                showRequiredErrors={hasClickedSendMeeting}
                isMeetingEditable={isMeetingEditable}
                setMeetingEditable={setMeetingEditable}
              />
            </Box>

            {/* AGENDA DETAILS (TOPICS) */}
            <Paper sx={{ mb: 3 }} variant="outlined" data-cy="meeting-prepare_topics">
              <Stack spacing={2}>
                {!meeting.minutesStartedAt && (
                  <Box>
                    <Box sx={{ p: 3 }}>
                      <VisibleByAccordion
                        title={t(keyPrefix + '.titleAgenda')}
                        prefixVisibleToSome={t(keyPrefix + '.prefixAgendaVisibleToSome')}
                        prefixVisibleToAll={t(keyPrefix + '.prefixAgendaVisibleToAll')}
                        chip={
                          meeting.agendaSentAt
                            ? {
                                color: 'success',
                                label: t(keyPrefix + '.chipAgendaSent')
                              }
                            : {
                                color: 'default',
                                label: t(keyPrefix + '.chipAgendaInPreparation')
                              }
                        }
                        usersVisibleByIds={meeting.meetingCreatorIds}
                        visibleByState={meeting.agendaSentAt ? 'all' : 'some'}
                        // isVisibleByAllMessageHidden={!isUserMeetingCreator}
                        icon={<ListAltIcon />}
                      />
                    </Box>
                    <Divider sx={{ mx: 3 }} />
                  </Box>
                )}
                {filteredTopicsStart?.map((topic, index) => {
                  const previousTopic = topics?.[index - 1];
                  const nextTopic = topics?.[index + 1];
                  const isPreviousTopicMovable = !checkIsUnmovableTopicType(previousTopic?.type || '');
                  const isNextTopicMovable = !checkIsUnmovableTopicType(nextTopic?.type || '');

                  return (
                    <Box key={topic.id} sx={{ mt: '0px !important' }}>
                      <MeetingTopicCardEditableMovable
                        meetingId={meeting.id}
                        topic={topic}
                        handleSetTopicEditable={handleSetTopicEditable}
                        order={index + 1}
                        isEditable={topic.id === topicIdEditable}
                        users={users}
                        participants={meetingParticipants}
                        isMeetingStarted={false}
                        onSubmitSuccess={handleUnsetTopicEditable}
                        previousTopicId={isPreviousTopicMovable ? previousTopic?.id : undefined}
                        nextTopicId={isNextTopicMovable ? nextTopic?.id : undefined}
                      />

                      <Divider sx={{ mx: 3 }} />
                    </Box>
                  );
                })}
                <>
                  <Box sx={{ px: 3 }}>
                    <AddTopicButton
                      meeting={meeting}
                      workspaceId={workspace.workspace?.id}
                      setTopicIdEditable={setTopicIdEditable}
                    />
                    <Divider sx={{ mt: 2 }} />
                  </Box>
                </>
                {filteredTopicsEnd?.map((topic, index) => {
                  return (
                    <Box key={topic.id} sx={{ mt: '0px !important' }}>
                      <MeetingTopicCardEditableMovable
                        meetingId={meeting.id}
                        topic={topic}
                        handleSetTopicEditable={handleSetTopicEditable}
                        order={notEndTopicCount + index + 1}
                        isEditable={topic.id === topicIdEditable}
                        users={users}
                        participants={meetingParticipants}
                        isMeetingStarted={false}
                        onSubmitSuccess={handleUnsetTopicEditable}
                        activeCommitteeMembers={meeting.activeCommitteeMembers}
                      />
                      {index !== filteredTopicsEnd.length - 1 && <Divider sx={{ mx: 3 }} />}
                    </Box>
                  );
                })}
              </Stack>
            </Paper>

            <StartMeetingBanner meeting={meeting} sx={{ mb: 3 }} />
          </Box>

          <Stack justifyContent="space-between" direction={{ xs: 'column-reverse', md: 'row' }}>
            {/* TIMELINE */}
            <Box sx={{ mt: { xs: 4, md: 0 } }}>
              <EventsTimeline timelineEvents={timelineEvents} />
            </Box>

            {/* MEETING TIME CALCULATION */}
            <Box sx={{ display: 'flex' }}>
              <TimerOutlinedIcon color="action" sx={{ mr: 1 }} />
              <Typography align="right">
                {t(keyPrefix + '.totalMeetingTime')}: {calculateMeetingDuration(t, topics)}
              </Typography>
            </Box>
          </Stack>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 4, alignItems: 'flex-start' }}>
            {/* mobile back */}
            <Button
              component={RouterLink}
              to="/"
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
            <>
              <DeleteMeeting
                meeting={meeting}
                isOpen={isDeleteMeetingOpen}
                onClose={handleCloseDeleteModal}
              />
              <Button
                onClick={handleOpenDeleteModal}
                sx={{ color: 'red', display: { xs: 'none', sm: 'block' } }}
                data-cy="meeting-prepare_delete-meeting-button"
              >
                {t(keyPrefix + '.labelDeleteMeeting')}
              </Button>
            </>
            <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ ml: 'auto' }}>
              {/* desktop back */}
              <Button component={RouterLink} to="/" sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>
                {t('common:labelBack')}
              </Button>
              <RenderPrintButton componentRef={printComponentRef} variant="outlined" />
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{renderSendMeetingButton()}</Box>
            </Stack>
          </Box>
        </Box>
      </PageContainer>
    </>
  );
};
