import { useCallback, useEffect } from 'react';
import {
  useComponentVisibility,
  useLocale,
  useParamsKey,
  useSearchParams,
  usePermissions
} from '@koopajs/react';
import { Typography, Box, Stack, Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { IMeeting, IMinute, IUploadedMinute } from 'types';
import { Loading } from '@koopajs/mui';
import { CreateButton } from 'components/CreateButton';
import { Sidebar } from 'components/Sidebars/Sidebar';
import { FilterChips } from '../components/FilterChips';
import { MobileFloatingButtons } from 'components/MobileFloatingButtons';
import { MinutesList } from 'components/Minutes/MinutesList';
import { Helmet } from 'react-helmet';
import { useResourceList } from 'components/temp/useResourceListTemp';
import { EmptyState } from 'components/EmptyState';
import { NoResultsResetFilters } from 'components/NoResultsResetFilters';
import { ViewMinutes } from 'components/Dialogs/ViewMinutes';
import { PageContainer } from 'components/temp/PageContainer';
import { ViewPastMeeting } from 'components/Dialogs/ViewPastMeeting';
import { FacetListMinutesInline } from 'components/Minutes/FacetListMinutesInline';
import { FacetListMinutesSidebar } from 'components/Sidebars/Minutes/FacetListMinutesSidebar';
import { UploadMinuteButton } from 'components/UploadedMinutes/UploadMinuteButton';
import { ViewUploadedMinutes } from 'components/Dialogs/ViewUploadedMinutes';

export const Minutes: React.FC = () => {
  const { t } = useLocale();
  const keyPrefix = 'Minutes';

  const minutesId = useParamsKey('minutesId');
  const uploadedMinutesId = useParamsKey('uploadedMinutesId') as string | undefined;
  const pastMeetingId = useParamsKey('pastMeetingId');

  const search = useSearchParams();

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const committeeFilter = search.searchParams?.filters?.find((filter) =>
    filter.startsWith('committeeId.keyword')
  );

  let currentCommitteeId: undefined | string = undefined;
  if (committeeFilter) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [type, ...value] = committeeFilter.split(':');
    currentCommitteeId = value.join(':').replace(/"/g, '');
  }

  const sidebarVisibility = useComponentVisibility('sidebar');

  const handlePageChange = useCallback((page: number): void => {
    search.setSearchParams({ page });
  }, []);

  const handleSizeChange = useCallback((size: number): void => {
    search.setSearchParams({ size, page: undefined });
  }, []);

  useEffect(() => {
    search.setSearchParams({ page: undefined });
  }, [JSON.stringify(search?.searchParams?.filters)]);

  const searchParamsObj = {
    sort: 'startDateTime:DESC',
    ...search.searchParams
  };

  const {
    resources: minutesList,
    state: minutesState,
    isProcessing,
    totalCount
  } = useResourceList<IMinute | IUploadedMinute>({
    path: '/minutes',
    searchParams: searchParamsObj
  });

  const { resources: meetings, state: meetingsState } = useResourceList<IMeeting>({
    path: '/meetings',
    searchParams: {
      filters: ['NOT _exists_:boardApprovedAt', 'NOT $model:"uploaded-meeting"'],
      size: 50
    }
  });

  if (meetingsState === 'loading' || meetingsState === 'reloading' || minutesState === 'loading')
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );

  const hasNoMeetings = meetingsState === 'loaded-empty' || meetingsState === 'loaded-no-search-result';

  // there are no meetings and no minutes
  if (hasNoMeetings && minutesState === 'loaded-empty')
    return (
      <EmptyState
        button={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box>
              <CreateButton otherButtonProps={{ disableElevation: true }} />
            </Box>

            {isUserWorkspaceOwner && (
              <UploadMinuteButton
                variant="contained"
                buttonLabel={t(keyPrefix + '.addPastMinutesButtonLabel')}
              />
            )}
          </Box>
        }
      />
    );

  const pendingMinutesCount = meetings.reduce((acc, curMeeting) => {
    if (!curMeeting.minutesEndedAt) return acc;
    if (currentCommitteeId && !(currentCommitteeId === curMeeting.committeeId)) return acc;
    return acc + 1;
  }, 0);

  // there are meetings that have not ended,  no minutes
  if (meetingsState === 'loaded' && minutesState === 'loaded-empty' && !pendingMinutesCount)
    return (
      <EmptyState
        subTitle={t(keyPrefix + '.emptyStateSubTitleNoMinutes')}
        description={t(keyPrefix + '.emptyStateDescriptionNoMinutes')}
        button={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {isUserWorkspaceOwner && (
              <UploadMinuteButton
                variant="contained"
                buttonLabel={t(keyPrefix + '.addPastMinutesButtonLabel')}
              />
            )}
          </Box>
        }
      />
    );

  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.minutes')} - Panorama`}</title>
      </Helmet>
      <PageContainer sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }} className="rr-block">
        <Sidebar facetList={<FacetListMinutesSidebar />} onHideSidebar={sidebarVisibility.setHidden} />
        <MobileFloatingButtons
          fabData={[
            {
              label: t('common:filters'),
              onClick: sidebarVisibility.setVisible
            }
          ]}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h1" sx={{ fontSize: '24px' }}>
            {t(keyPrefix + '.title')}
          </Typography>
          <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            <FacetListMinutesInline />
          </Box>
        </Box>
        <Box sx={{ my: '24px' }}>
          <FilterChips isProcessing={isProcessing} />
        </Box>
        {/* there are ended meetings to approve*/}
        {Boolean(pendingMinutesCount) && meetingsState === 'loaded' && (
          <Box>
            <Stack
              component={Paper}
              direction="row"
              variant="outlined"
              sx={{ py: 1, px: 3, my: 3, alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Box>{t(keyPrefix + '.noticeMinutesToAdopt', { count: pendingMinutesCount })}</Box>
              <Button component={Link} to="/pending-review">
                {t('common:labelView')}
              </Button>
            </Stack>
            {/* there are no minutes and uploaded minutes */}
            {minutesState === 'loaded-empty' && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {isUserWorkspaceOwner && <UploadMinuteButton />}
              </Box>
            )}
          </Box>
        )}
        {(minutesState === 'loaded' || minutesState === 'reloading') && (
          <MinutesList
            minutes={minutesList}
            totalCount={totalCount}
            size={search.searchParams.size}
            page={search.searchParams.page}
            isReloading={minutesState === 'reloading'}
            onPageChange={handlePageChange}
            onSizeChange={handleSizeChange}
          />
        )}
        {minutesState === 'loaded-no-search-result' && (
          <>
            <NoResultsResetFilters />
            {isUserWorkspaceOwner && <UploadMinuteButton />}
          </>
        )}
        <ViewMinutes minutesId={minutesId} pathOnClose="/minutes" />
        <ViewUploadedMinutes uploadedMinutesId={uploadedMinutesId} />
        <ViewPastMeeting pastMeetingId={pastMeetingId} pathOnClose="/minutes" />
        <Box sx={{ display: { lg: 'none' }, height: `${40 + 30}px` }} />
      </PageContainer>
    </>
  );
};
