import React from 'react';
import { Pages } from '@koopajs/mui';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Account } from './Account';
import { NotFound } from './NotFound';
import { Settings } from './Settings';
import { Dashboard } from './Dashboard';
import { Minutes } from './Minutes';
import { Organization } from './Organization';
import { ResolutionStateConditions } from './ResolutionStateConditions';
import { InCamera } from './InCamera';
import { DocumentView } from './DocumentView';
import { useWorkspaceShow } from '@koopajs/react';
import { OrganizationCommittee } from './OrganizationCommittee';
import { OrganizationMember } from './OrganizationMember';
import { ToSignPageMinutes } from 'components/Dashboard/ToSign/ToSignPageMinutes';
import { DocumentRecords } from './DocumentRecords';
import { DocumentRecordView } from './DocumentRecordView';
import { PastMeetings } from './PastMeetings';
import { DocumentRecordsCategory } from './DocumentRecordsCategory';
import { DocumentRecordsCommittee } from './DocumentRecordsCommittee';
import { DocumentAttachedToDocumentRecordView } from './DocumentAttachedToDocumentRecordView';
import { MeetingEdit } from './MeetingEdit';
import { MeetingInProgress } from './MeetingInProgress';
import { MeetingReview } from './MeetingReview';
import { MeetingView } from './MeetingView';
import { UploadedMinutes } from './UploadedMinutes';
import { UploadedMeetings } from './UploadedMeetings';
import { UploadedResolutions } from './UploadedResolutions';

const BillingPage = (): React.ReactElement => {
  // TODO: add translations
  return <Pages.BillingPage />;
};

const RedirectComponent = (): React.ReactElement => {
  return <Redirect to="/" />;
};

export const Router: React.FC = () => {
  const { workspace } = useWorkspaceShow();
  return (
    <Switch>
      <Route path={['/', '/:key(pending-review|to-sign)']} exact component={Dashboard} />
      <Route
        path={[
          '/meetings/:meetingId/topics/:topicId',
          '/meetings/:meetingId/topics/:topicId/:key(agenda-approval)',
          '/meetings/:meetingId/topics/:topicId/past-minutes/:pastMinutesId'
        ]}
        exact
        component={MeetingInProgress}
      />
      <Route
        path={['/meetings/:meetingId/review', '/meetings/:meetingId/review/:key(agenda)']}
        exact
        component={MeetingReview}
      />
      <Route path={'/meetings/:meetingId/edit'} exact component={MeetingEdit} />
      <Route path={['/meetings/:meetingId']} exact component={MeetingView} />
      <Route path={['/uploaded-minutes']} exact component={UploadedMinutes} />
      <Route path={['/uploaded-resolutions']} exact component={UploadedResolutions} />
      <Route path="/minutes" exact component={Minutes} />
      <Route path="/minutes/past-meetings/:pastMeetingId" exact component={Minutes} />
      <Route path="/minutes/:minutesId/sign" exact component={ToSignPageMinutes} />
      <Route
        path={['/minutes/:minutesId', '/uploaded-minutes/:uploadedMinutesId']}
        exact
        component={Minutes}
      />
      <Route
        path={[
          '/past-meetings/:id?',
          '/past-meetings/minutes/:minutesId',
          '/uploaded-meetings/:uploadedMeetingId'
        ]}
        exact
        component={PastMeetings}
      />
      <Route path={['/uploaded-meetings']} exact component={UploadedMeetings} />
      <Route path="/documents/:id" exact component={DocumentView} />
      <Route path="/resolutions/minutes/:minutesId" exact component={ResolutionStateConditions} />
      <Route
        path={['/resolutions/:id?', '/uploaded-resolutions/:uploadedResolutionId']}
        exact
        component={ResolutionStateConditions}
      />
      <Route path="/resolutions/:id/sign" exact component={ResolutionStateConditions} />
      <Route path="/in-camera/:id?" exact component={InCamera} />
      <Route path={['/organization', '/organization/:key(members)']} exact component={Organization} />
      <Route path="/organization/:id" exact component={OrganizationCommittee} />
      <Route path="/organization/members/:id" exact component={OrganizationMember} />
      <Route path="/document-records" exact component={DocumentRecords} />
      <Route path="/document-records/:id" exact component={DocumentRecordView} />
      <Route path="/document-records/committees/:id" exact component={DocumentRecordsCommittee} />
      <Route
        path="/document-records/categories/:key(constituent-documents|policies|budgets-and-financial-statements|strategy-and-reports)"
        exact
        component={DocumentRecordsCategory}
      />
      <Route
        path="/document-records/:id/documents/:documentId"
        exact
        component={DocumentAttachedToDocumentRecordView}
      />
      <Route path="/settings" component={workspace?.isDemo ? RedirectComponent : Settings} />
      <Route path="/account" component={Account} />
      <Route path="/billing" exact component={BillingPage} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};
